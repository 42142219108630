<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="网站标题">
        <a-input v-model:value="formState.title" placeholder="请输入网站标题" />
      </a-form-item>

      <a-form-item label="网站logo">
        <CsImage v-model:imgOne="formState.logo" />
      </a-form-item>
      <a-form-item label="网站关键字" extra="多个关键字之间用英文“,”隔开">
        <a-input v-model:value="formState.keywords" placeholder="请输入网站关键字" />
      </a-form-item>
      <a-form-item label="网站描述">
        <a-textarea v-model:value="formState.description" placeholder="请输入网站描述" />
      </a-form-item>
      <a-form-item label="关键词过滤" extra="多个关键字之间用英文“,”隔开">
        <a-textarea v-model:value="formState.forbidden_words" placeholder="请输入关键词" />
      </a-form-item>
      <a-form-item label="急救电话" extra="请输入急救电话">
        <a-input v-model:value="formState.poend" placeholder="请输入急救电话" />
      </a-form-item>
      <a-form-item label="咨询电话" extra="请输入咨询电话">
        <a-input v-model:value="formState.consult" placeholder="请输入咨询电话" />
      </a-form-item>
      <a-form-item label="邮编" extra="请输入邮编">
        <a-input v-model:value="formState.zipcode" placeholder="请输入邮编" />
      </a-form-item>
      <a-form-item label="Email" extra="请输入Email">
        <a-input v-model:value="formState.email" placeholder="请输入Email" />
      </a-form-item>
      <a-form-item label="医院地址" extra="请输入医院地址">
        <a-input v-model:value="formState.address" placeholder="请输入医院地址" />
      </a-form-item>
      <a-form-item label="浏览次数" extra="请输入浏览次数">
        <a-input v-model:value="formState.counter" placeholder="请输入浏览次数" />
      </a-form-item>
      <a-form-item label="系统状态">
        <a-switch v-model:checked="formState.status" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import CsImage from "@/components/UpImage";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    const formState = reactive({
      title: "",
      logo: "",
      keywords: "",
      description: "",
      status: true,
      poend:"",
      consult:"",
      zipcode:"",
      email:"",
      address:"",
      counter:0,
      forbidden_words:'',
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
        formState.title = res.data.title ? res.data.title : "";
        formState.logo = res.data.logo ? res.data.logo : "";
        formState.keywords = res.data.keywords ? res.data.keywords : "";
        formState.description = res.data.description
          ? res.data.description
          : "";
          formState.poend = res.data.poend ? res.data.poend : "";
          formState.consult = res.data.consult ? res.data.consult : "";
          formState.zipcode = res.data.zipcode ? res.data.zipcode : "";
          formState.email = res.data.email ? res.data.email : "";
          formState.address = res.data.address ? res.data.address : "";
          formState.counter = res.data.counter ? res.data.counter : 0;
          formState.forbidden_words = res.data.forbidden_words ? res.data.forbidden_words : "";
        formState.status = res.data.status == "1" ? true : false;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>